/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it
import React from "react"
import "core-js/features/set-immediate"

import "./src/config"

// eslint-disable-next-line css-import-order/css-import-order
import "semantic-ui-css/semantic.min.css"
// eslint-disable-next-line css-import-order/css-import-order
import "balloon-css/balloon.min.css"
// eslint-disable-next-line css-import-order/css-import-order
import "decentraland-ui/dist/themes/base-theme.css"
// eslint-disable-next-line css-import-order/css-import-order
import "decentraland-ui/dist/themes/alternative/dark-theme.css"
// eslint-disable-next-line css-import-order/css-import-order
import "./src/theme.css"

import { responsiveFontSizes } from "@mui/material/styles"
import AuthProvider from "decentraland-gatsby/dist/context/Auth/AuthProvider"
import FeatureFlagProvider from "decentraland-gatsby/dist/context/FeatureFlag/FeatureFlagProvider"
import { IntlProvider } from "decentraland-gatsby/dist/plugins/intl"
import segment from "decentraland-gatsby/dist/utils/segment/segment"

import { DclThemeProvider, darkTheme } from "decentraland-ui2"

export const wrapRootElement = ({ element }) => (
  <>
    <AuthProvider>
      <FeatureFlagProvider applicationName={["landing", "dapps"]}>
        <DclThemeProvider
          theme={responsiveFontSizes(darkTheme, {
            factor: 2.5,
            breakpoints: ["sm", "md", "xl"],
          })}
        >
          {element}
        </DclThemeProvider>
      </FeatureFlagProvider>
    </AuthProvider>
  </>
)

export const wrapPageElement = ({ element, props }) => {
  return <IntlProvider {...props.pageContext.intl}>{element}</IntlProvider>
}

export const onClientEntry = () => {
  segment((analytics) => analytics.page())
}

export const onRouteUpdate = () => {
  segment((analytics) => analytics.page())
}
